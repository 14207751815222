import { css } from 'styled-components';

export const elevated = css`
	border: none;
	color: var(--color-primary-_500);
	box-shadow:
		0 1px 2px 0 rgba(0, 0, 0, 0.3),
		0 1px 3px 1px rgba(0, 0, 0, 0.15);
	background-color: transparent;
	&::after {
		background-color: var(--color-primary-_500);
		opacity: 0.05;
	}
	&:hover {
		background-color: var(--color-primary-_500);
		opacity: 0.05;
	}
	&:hover:not([disabled]) {
		&::after {
			background-color: var(--color-primary-_500);
			opacity: 0.04;
		}
	}
	&:focus {
		&::after {
			background-color: var(--color-primary-_500);
			opacity: 0.12;
		}
	}
	&:active:not([disabled]) {
		&::after {
			background-color: var(--color-primary-_500);
			opacity: 0.1;
		}
	}
	&:disabled {
		color: rgba(0, 0, 0, 0.38);
	}
`;
