import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ButtonContent } from './style';
import { ButtonProps } from './types';
import { Skeleton } from 'ui-library/components/layout/Skeleton';
import { ButtonSkeleton } from './skeleton';

export const Button = ({
	size = 'M',
	variant = 'filled',
	type = 'button',
	autofocus,
	disabled,
	form,
	icon,
	children,
	onClick,
	isLoading = false,
}: ButtonProps) => {
	if (isLoading)
		return <Skeleton renderSkeleton={() => <ButtonSkeleton />} />;

	return (
		<ThemeProvider theme={{ variant, size }}>
			<ButtonContent
				autofocus={autofocus}
				disabled={disabled}
				form={form}
				icon={icon}
				type={type}
				onClick={onClick}>
				{children}
			</ButtonContent>
		</ThemeProvider>
	);
};

export default Button;
