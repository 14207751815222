import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { AccordionSkeletonSC } from './style.skeleton';

interface AccordionSkeletonProps extends IContentLoaderProps {
	order: number;
}

export const AccordionSkeleton = (props: AccordionSkeletonProps) => {
	return (
		<AccordionSkeletonSC
			speed={2}
			width={960}
			backgroundColor="#f3f3f3"
			foregroundColor="#ece0e0"
			className={props.order === 0 ? 'opened-accordion' : ''}
			{...props}>
			<rect x="0" y="0" width="100%" height="100%" />
		</AccordionSkeletonSC>
	);
};
export default AccordionSkeleton;
