import type { CrmData } from 'statics/services/AppContext';
import { Font } from 'statics/lib/types/_fonts';
import { replaceVars } from 'statics/lib/replacePageDataVariables';
import { getMessages, getTranslations } from 'next-intl/server';

/**
 * Recursively replace dynamic variables in a string with their corresponding values.
 *
 * @async
 * @function
 * @param str - The string to replace variables in.
 * @returns A promise that resolves to the modified string.
 */
const replaceSectionCrmVarsInString = async ({
	str,
	crmData,
	font,
}: {
	str: string;
	crmData: CrmData;
	font: Font;
}): Promise<string> => {
	return await replaceVars(str, crmData, font);
};

/**
 * Recursively replace dynamic variables in an array.
 *
 * @async
 * @function
 * @param arr - The array to replace variables in.
 * @returns A promise that resolves to the modified array.
 */
const replaceSectionCrmVarsInArray = async ({
	arr,
	crmData,
	font,
}: {
	arr: any[];
	crmData: CrmData;
	font: Font;
}): Promise<any[]> => {
	return Promise.all(
		arr.map(
			async (item) =>
				await replaceSectionCrmVars({
					element: item,
					crmData,
					font,
				}),
		),
	);
};

/**
 * Recursively replace dynamic variables in an object.
 *
 * @async
 * @function
 * @param obj - The object to replace variables in.
 * @returns A promise that resolves to the modified object.
 */
const replaceSectionCrmVarsInObject = async ({
	obj,
	crmData,
	font,
}: {
	obj: Record<string, any>;
	crmData: CrmData;
	font: Font;
}): Promise<Record<string, any>> => {
	let result: Record<string, any> = {};
	for (const key in obj) {
		result[key] = await replaceSectionCrmVars({
			element: obj[key],
			crmData,
			font,
		});
	}
	return result;
};

/**
 * Recursively replaces dynamic variables in an object with their
 * corresponding values.
 *
 * @async
 * @function
 * @param options - The CRM variables replacement options.
 * @param options.element - The element to process.
 * @param options.crmData - Data received from the CRM.
 * @param options.font - The font used in the element for conversion of
 * 						 the CRM variables to SVGs.
 * @returns A promise that resolves to the modified object or string.
 */
const replaceSectionCrmVars = async ({
	element,
	crmData,
	font,
}: {
	element: Record<string, any>;
	crmData: CrmData;
	font: Font;
}): Promise<Record<string, any> | string> => {
	if (typeof element === 'string') {
		return replaceSectionCrmVarsInString({
			str: element,
			crmData,
			font,
		});
	} else if (Array.isArray(element)) {
		return await replaceSectionCrmVarsInArray({
			arr: element,
			crmData,
			font,
		});
	} else if (typeof element === 'object' && element !== null) {
		return await replaceSectionCrmVarsInObject({
			obj: element,
			crmData,
			font,
		});
	}
	return element;
};

export {
	replaceSectionCrmVarsInString,
	replaceSectionCrmVarsInArray,
	replaceSectionCrmVarsInObject,
	replaceSectionCrmVars,
};
