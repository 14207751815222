/** ******************************************************************************
 * @todo TODO:
 *  - Check and correct 'any' typings.
 *
 * This code search for varnames between brackets {{variableName}} and replace them with API final value.
 * NOTE: Search given a {{variableName}} in every existing level.
 *
 *                              /\
 *                             /  \
 *                            |    |
 *                          --:'''':--		"Abracadabra ...
 *                            :'_' :			... mapea variable con palabra ..."
 *                            _:"":\___
 *             ' '      ____.' :::     '._
 *            . *=====<<=)           \    :
 *             .  '      '-'-'\_      /'._.'
 *                              \====:_ ""
 *                             .'     \\
 *                            :       :
 *                           /   :    \
 *  (plof!)                 :   .      '.
 *          ,. _            :  : :      :
 *       '-'    ).          :__:-:__.;--'
 *     (        '  )        '-'   '-'
 *  ( -   .00.   - _
 * (    .'  _ )     )
 * '-  ()_.\,\,   -
 ****************************************************************************** */
import React from 'react';
import TextToSVG from '@npm_leadtech/jsr-text-to-svg';
import { CrmData } from 'statics/services/AppContext';
import type { Filter } from './types';
import type { Font } from '../types/_fonts';

/**
 * NOTE: Config constant variables for replace/translate functions.
 *
 * @var FILTER_COLLECTION => Available filters for text processing.
 * @var FILTER_DELIMITER => Delimiter between variable name and filter. Ex.: {{ variableName, image }}
 * @var EXCLUDED_PROPERTIES => CrmData props to exclude searching in.
 */
const FILTER_COLLECTION: Filter[] = [
	'image', // Converts text to SVG
	'subdomain', // Replace url's with subdomains url's (Ex.: www.domain.com -> online.domain.com))
	'forceUrl',
];
const FILTER_DELIMITER = ',';
const EXCLUDED_PROPERTIES = ['countries', 'prices'];

/**
 * Finds a value within object properties or a string based on a property name.
 *
 * @param {object | string} targetObject The object or string to search within.
 * @param {string} propertyName - The name of the property to search for.
 * @returns {string} The found value or an empty string if not found.
 */
export const findValueInObjectProperties = (
	targetObject: { [key: string]: any } | string,
	propertyName: string,
): string => {
	let foundValue = '';
	if (typeof targetObject === 'object') {
		const propertyPath = propertyName.split('.');
		if (propertyPath.length > 1) {
			foundValue = getObjectPropertyByPath(
				targetObject,
				propertyPath,
			); // 🔍
		} else {
			Object.values(targetObject).forEach((propertyValue) => {
				if (!EXCLUDED_PROPERTIES.includes(propertyValue)) {
					const result =
						searchPropertyValue(
							propertyValue,
							propertyName,
						) ?? ''; // 🔍
					if (result !== '') foundValue = result; // 🏆
				}
			});
		}
	}
	return foundValue;
};

/**
 * Retrieves the value from an object based on a given dot-separated path.
 *
 * @param {object} targetObject - The object to retrieve the value from.
 * @param {string[]} propertyPath - The dot-separated path array (e.g.,
 * ['prop1', 'prop2', 'prop3']).
 * @returns {string} - The value at the given path, or undefined if the path
 * does not exist.
 */
const getObjectPropertyByPath = (
	targetObject: { [key: string]: any } | string,
	propertyPath: string[],
): string => {
	if (typeof targetObject !== 'object' || targetObject === null)
		return '';
	let currentObj = targetObject;
	for (let i = 0; i < propertyPath.length; i++) {
		if (currentObj === undefined || currentObj === null) {
			return '';
		}
		currentObj = Array.isArray(currentObj)
			? currentObj[0][propertyPath[i]]
			: currentObj[propertyPath[i]];
	}
	return currentObj.toString() || '';
};

/**
 * Searches for a property value within an object or string based on a property name.
 *
 * @param {object | string} targetObject The object or string to search within.
 * @param {string} propertyName - The name of the property to search for.
 * @returns {string} The found value or an empty string if not found.
 */
export const searchPropertyValue = (
	targetObject: any,
	propertyName: string,
): string => {
	if (!targetObject) return ''; // ❌ no obj, no search.
	if (targetObject[propertyName]) return targetObject[propertyName]; // 🏆 win!
	return findValueInObjectProperties(targetObject, propertyName); // 🔍 not this level, keep digging.
};

/**
 * Create an SVG version of the given text.
 *
 * @async
 * @param variableValue Variable value to parse.
 * @param font Font family variable's object.
 * @returns {Promise<string>}
 */
export const parseTextToSVG = async (
	variableValue: string,
	font: Font,
): Promise<string> => {
	const [fontFilename] = font.body.family.regular.split(', ');
	const [fontSize] = /\d+/.exec(font.body.size.L.mobile!!) || ['16'];
	const SVGparser = new TextToSVG({
		font: `/common/fonts/${fontFilename}.ttf`,
		fontSize,
		options: {
			letterSpacing: 0,
		},
	});
	return await SVGparser.toSVG(variableValue).catch(
		(err: unknown) => {
			throw new Error(
				`SVGParser.toSVG() failed parsing '${variableValue}' to SVG.`,
				{
					cause: err,
				},
			);
		},
	);
};

/**
 * Removes curly braces from a variable string.
 *
 * @param {string} variable The {{variable}} string to process.
 * @returns {string} The variable string without curly braces.
 */
export const removeCurlyBraces = (variable: string): string =>
	variable.slice(2, -2);

/**
 * Extracts the variable name from a variable string and handles the 'image' filter.
 *
 * @param {string} variable The {{variable}} string to process.
 * @returns {string} The variable name.
 */
export const extractVariableName = (variable: string): string => {
	const regex = new RegExp(
		`\\s*${FILTER_DELIMITER}\\s*(${FILTER_COLLECTION.join('|')})`,
	);
	return removeCurlyBraces(variable).split(regex)[0].trim();
};

/**
 * Extract filter applied to variable.
 *
 * @param {string} variableName The variable name to check.
 * @returns {Filter | null} Filter value or null if none found.
 */
export const getFilterName = (variableName: string): Filter | null => {
	const variableNameSplit = variableName.split(',');
	if (variableNameSplit.length <= 1) {
		return null;
	}
	const [, filterName] = variableNameSplit;
	const filterNameTrimmed = filterName.trim() as Filter;
	if (!FILTER_COLLECTION.includes(filterNameTrimmed)) {
		return null;
	}
	return filterNameTrimmed;
};

/**
 * Extracts unique variable names from a source string.
 *
 * @param {string} sourceString The source string to search for variable patterns.
 * @returns {string[]} An array of unique variable names.
 */
export const extractUniqueVariableNames = (
	sourceString: string,
): string[] => {
	const variableRegex = new RegExp(
		`\\{\\{(\\s*)?([\\w.]+)(?:\\s*${FILTER_DELIMITER}\\s*(${FILTER_COLLECTION.join(
			'|',
		)}))?(\\s*)?\\}\\}`,
		'g',
	);
	return [...new Set(sourceString.match(variableRegex) || [])];
};

/**
 * Build a map of dynamic variables and their corresponding values.
 * If a variable has the 'image' filter enabled, it will be replaced with an SVG element string.
 *
 * @async
 * @param {string} sourceString The string containing dynamic variables to be replaced.
 * @param {CrmData} crmData The API data used for replacement.
 * @param {ThemeVarsProps} variables The styling variables.
 * @returns {Promise<{ [variableName: string]: string }>} A map with variable names as keys and their values.
 */
export const buildDynamicVariablesMap = async (
	sourceString: string,
	crmData: CrmData,
	font: Font,
): Promise<Record<string, string>> => {
	const variableMap: Record<string, string> = {};
	const variablesToReplace = extractUniqueVariableNames(sourceString);
	const flatCrmData = { ...crmData.productType, ...crmData.site };
	await Promise.all(
		variablesToReplace.map(async (variable) => {
			const variableWithoutCurlyBraces =
				removeCurlyBraces(variable);
			const variableValue = searchPropertyValue(
				flatCrmData,
				extractVariableName(variable),
			);
			switch (getFilterName(variableWithoutCurlyBraces)) {
				case 'image':
					variableMap[variableWithoutCurlyBraces] =
						await parseTextToSVG(variableValue, font);
					break;
				case 'subdomain':
					/**
					 * FIXME: HACK: get Host from window.location instead of CRM data
					 * because Next is saving static values into a file called
					 * index.txt and that means multiple domains with the same
					 * theme share that data (we don't want that).
					 * ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣠⠤⠤⠔⢒⡖⢒⣦⣤⠀⠀⠀⠀⠀⠀⠀⠀⠀
						⠀⠀⠀⠀⠀⠀⠀⢀⡴⠖⠛⠉⠁⠐⠒⠒⡾⣷⠾⠯⠈⠑⢤⠀⠀⠀⠀⠀⠀⠀
						⠀⠀⠀⠀⠀⠀⣰⠋⠀⠀⠀⠀⠀⠀⠀⣟⣿⡇⠀⠀⠀⠀⠀⢳⡀⠀⠀⠀⠀⠀
						⠀⠀⣠⠄⣶⣾⣁⡀⠀⠀⠀⠀⠀⠀⣰⣿⣾⠃⢀⡿⠛⠓⣄⠀⢿⣆⠀⠀⠀⠀
						⠀⢀⢀⡴⠋⠀⠈⠙⢿⡷⠂⠀⠀⠀⡿⡿⠁⡔⠁⠀⠀⡆⠈⢣⡈⢾⡆⠀⠀⠀
						⠀⢸⠎⠀⠀⢀⡠⠤⠤⠽⣄⠀⠀⠀⠹⠇⠸⠀⠀⠀⠀⠉⠐⠊⠹⡌⢳⡀⠀⠀
						⠀⣿⠀⠀⢰⣋⡀⠀⠀⠀⠘⣗⡆⠀⠀⠀⠘⣄⠀⠀⠀⠀⠀⠀⠀⢱⠈⣷⡄⠀
						⡇⡏⠀⠀⣇⣠⠟⠀⠀⠀⠀⢀⠇⠀⢀⣀⣀⣈⠢⣄⡀⠀⠀⠀⣠⠎⠀⢸⡱⡀
						⣿⠀⠀⠀⢸⠀⠀⠀⠀⠀⣠⠋⡠⠚⣅⠀⢸⢄⡩⠓⢭⣉⢉⣉⠁⠀⠀⠀⢃⡇
						⣿⠀⠀⠀⠈⠢⣀⣀⣠⠞⠁⡰⣧⡀⢸⡗⠂⠀⠀⠀⠀⠈⠉⠚⠳⡄⠀⠀⠸⡇
						⣿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣇⡼⠋⠈⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠹⡄⠀⠀⡇
						⡟⡀⠀⠀⠀⠀⠀⠀⠀⠀⢸⠁⠀⠀⠀⠀⣀⣀⣠⠞⠉⠣⡀⢀⣀⡀⢸⠀⠀⡇
						⢣⢇⠀⠀⠀⠀⠀⠀⠀⠀⡸⠀⠀⠀⠀⡼⠁⠀⠁⣀⠀⡔⠙⡞⠀⠙⣸⠀⠀⡇
						⠸⡘⡄⠀⠀⠀⠀⠀⠀⠀⡇⠀⠀⠀⠘⠁⠀⢀⡎⠉⢓⣡⠔⠓⠒⠚⠁⠀⠀⢹
						⠀⠱⣇⠀⠀⠀⠀⠀⠀⡜⠀⠀⠀⠀⡔⠉⠙⢼⣀⠴⠋⠀⠀⠀⠀⠀⠀⠀⢀⡞
						⠀⠀⢹⢦⠀⠀⠀⠀⢸⣁⠜⠉⠑⠼⢀⣠⠤⠚⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⡼⠁
						⠀⠀⠸⡄⠓⢤⡀⠀⠀⠈⠉⠉⠑⠒⠋⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣰⠃⠀
						⠀⠀⠀⠙⠀⠀⠈⠙⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠃⠀⠀
					 * */

					// FIXME: HACK: environment variables not working in this case because they are obtained at build moment (only when stage is created. For this reason, always have stage value)
					const isStage =
						process.env!.NEXT_PUBLIC_APP_ENV === 'stage';
					const isProd =
						process.env!.NEXT_PUBLIC_APP_ENV === 'main';
					const host =
						isProd || isStage
							? window.location.host
							: variableValue;
					const replacedValue =
						window.location.host.startsWith('test.') ||
						window.location.host.startsWith('localhost:')
							? 'test.online'
							: 'online';
					//FIXME: HACK: the locale must be obtained from the server, not from the url
					const hasLocale = /^\/[a-z]{2}\//.test(
						window.location.pathname,
					);
					const locale =
						hasLocale &&
						window.location.pathname.match(
							/^\/[a-z]{2}/,
						)![0];

					variableMap[variableWithoutCurlyBraces] = hasLocale
						? host.replace(/www|test/g, replacedValue) +
						  locale
						: host.replace(/www|test/g, replacedValue);
					break;
				case 'forceUrl':
					// FIXME: HACK: If 'forceUrl' filter is present hardcode URL
					// with retrieved one from window.location.host
					variableMap[variableWithoutCurlyBraces] =
						useForceUrlFilter(
							variableWithoutCurlyBraces
								.split(',')
								.shift()!,
							variableValue,
						);
					break;
				default:
					variableMap[variableWithoutCurlyBraces] =
						variableValue;
					break;
			}
		}),
	).catch((err: unknown) => {
		throw new Error(
			`Function buildDynamicVariablesMap() failed parsing '${sourceString}'.`,
			{
				cause: err,
			},
		);
	});
	return variableMap;
};

function useForceUrlFilter(
	variableName: string,
	variableValue: string,
): string {
	const hostUrl = window.location.host;
	if (variableName.toLowerCase().includes('url')) {
		return hostUrl;
	} else if (variableName.toLowerCase().includes('email')) {
		const emailName = variableValue.split('@').shift();
		const emailDomain = hostUrl.split(/(www|test)./).pop();
		return `${emailName}@${emailDomain}`;
	}
	return variableValue;
}
/**
 * Replace dynamic variables in a string with their corresponding values.
 *
 * @param {string} sourceString The string containing dynamic variables to be replaced.
 * @param {Record<string, string>} variableMap The map with variable names as keys and their values.
 * @returns {string} The string with replaced values.
 */
export const replaceDynamicVariablesWithValues = (
	sourceString: string,
	variableMap: Record<string, string>,
): string => {
	Object.keys(variableMap).forEach((variableName) => {
		const regex = new RegExp(
			`{{(\s*)?${variableName}(\s*${FILTER_DELIMITER}\s*)?(${FILTER_COLLECTION.join(
				'|',
			)})?(\s*)?}}`,
			'g',
		);
		sourceString = sourceString.replace(
			regex,
			variableMap[variableName],
		);
	});
	return sourceString;
};

/**
 * - Main function -
 * Replace dynamic variables in a string with API values.
 *
 * @async
 * @param {string} sourceString The string containing dynamic variables to be replaced.
 * @param {CrmData} crmData The API data used for replacement.
 * @param {ThemeVarsProps} variables The styling variables.
 * @returns {Promise<string>} A Promise that resolves to the string with replaced values.
 */
export async function replaceVars(
	sourceString: string,
	crmData: CrmData,
	font: Font,
): Promise<string> {
	try {
		const dynamicVariablesMap = await buildDynamicVariablesMap(
			sourceString,
			crmData,
			font,
		);
		return replaceDynamicVariablesWithValues(
			sourceString,
			dynamicVariablesMap,
		);
	} catch (err: unknown) {
		throw new Error(err as string, { cause: err });
	}
}
