import React from 'react';
import { LinkSkeletonSC } from './style.skeleton';
import { IContentLoaderProps } from 'react-content-loader';

export const LinkSkeleton = (props: IContentLoaderProps) => (
	<LinkSkeletonSC
		speed={2}
		width={148}
		height={48}
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}>
		<rect x="0" y="0" rx="0" ry="0" width="148" height="48" />
	</LinkSkeletonSC>
);
