import styled from 'styled-components';

const SearchBoxContent = styled.div`
	display: inline-flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--color-neutral-_500);
	text-align: center;
	padding-left: 12px;
	margin: unset;

	input {
		font-family: var(--font-body-family-regular);
		font-size: var(--font-body-size-M-mobile);
		font-weight: 400;
		color: var(--color-neutral-_500);
		border: 0;
		padding: 12px 12px 12px 0;
	}

	svg {
		display: inline-block;
		margin-right: 10px;
		font-size: 1.1em;
	}
`;

export { SearchBoxContent };
