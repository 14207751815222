import { createContext, Dispatch, SetStateAction } from 'react';

/**
 * NOTE: This context will contain crmData value.
 * It'll be populated inside [slug].tsx 'getStaticProps' method.
 */
const CrmDataContext = createContext<{ [key: string]: any }>({});
export const FilterContext = createContext<
	[Array<any>, Dispatch<SetStateAction<any[]>>]
>([[], () => {}]);

export default CrmDataContext;
