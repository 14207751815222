import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { FooterSkeletonSC } from './style.skeleton';

interface FooterSkeletonProps extends IContentLoaderProps {
	showDisclaimer: boolean;
}

export const FooterSkeleton = ({
	showDisclaimer,
	...props
}: FooterSkeletonProps) => (
	<FooterSkeletonSC
		speed={2}
		width={960}
		height={showDisclaimer ? 294 : 276}
		backgroundColor="#f3f3f3"
		foregroundColor="#ece0e0"
		{...props}>
		{/* ssl + cards */}
		<rect x="0" y="48" width="45%" height="20" />
		<rect x="0" y="74" width="45%" height="129" />

		{/* links */}
		<rect x="55%" y="48" width="20%" height={20} />
		<rect x="55%" y="74" width="20%" height={20} />
		<rect x="55%" y="100" width="20%" height={20} />
		<rect x="55%" y="126" width="20%" height={20} />

		<rect x="80%" y="48" width="20%" height={20} />
		<rect x="80%" y="74" width="20%" height={20} />
		<rect x="80%" y="100" width="20%" height={20} />

		{showDisclaimer && (
			<rect x="0" y="253" width="100%" height="18" />
		)}
	</FooterSkeletonSC>
);

export default FooterSkeleton;
