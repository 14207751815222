import { css } from 'styled-components';

export const filled = css`
	color: var(--color-basic-white);
	background-color: var(--color-primary-_500);
	&:hover:not([disabled]) {
		box-shadow:
			0px 1px 3px 1px rgba(0, 0, 0, 0.15),
			0px 1px 2px rgba(0, 0, 0, 0.3);
		&::after {
			background-color: var(--color-basic-white);
			opacity: 0.04;
		}
	}
	&:focus {
		&::after {
			background-color: var(--color-basic-white);
			opacity: 0.12;
		}
	}
	&:active:not([disabled]) {
		&::after {
			background-color: var(--color-basic-white);
			opacity: 0.1;
		}
	}
	&:disabled {
		background-color: var(--color-neutral-_100);
		color: rgba(0, 0, 0, 0.38);
	}
`;
