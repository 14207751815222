import styled from 'styled-components';

const TableSC = styled.div`
	display: block;
	width: -webkit-fill-available;
	margin-bottom: 32px;
`;

const TableLayout = styled.table`
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	max-width: 100%;
`;

export { TableSC, TableLayout };
