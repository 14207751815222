import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { media } from 'ui-library/lib/breakpoints';

export const AccordionSkeletonSC = styled(ContentLoader)`
	width: 100%;
	margin-top: 5px;
	height: 103px;
	&.opened-accordion {
		height: 678px;
	}
	${media.smMin`
        height: 85px;
        &.opened-accordion {
            height: 447px;
        }`}
`;
