import { MoleculesMapper } from './moleculesMapper';
import type { MoleculesMapperProps } from './moleculesMapper';
import { OrganismsMapper } from './organismsMapper';
import type { OrganismsMapperProps } from './organismsMapper';
export type { AtomsMapperProps } from './atomsMapper';
export { AtomsMapper } from './atomsMapper';

export interface ComponentsMapperProps
	extends MoleculesMapperProps,
		OrganismsMapperProps {}

export const ComponentsMapper: ComponentsMapperProps = {
	...MoleculesMapper,
	...OrganismsMapper,
};
