import React from 'react';
import { ThemeProvider } from 'styled-components';
import { WrapStyled } from './style';
import { WrapperProps } from './types';

/**
 * Component to wrap other elements.
 * @component
 * @param children Children nodes wrapped inside this component.
 * @param direction Direction
 * @returns Wrapper containing any elements passed inside props.
 * @example
 * <Wrapper direction="">
 *    <div>
 *      <p>Example</p>
 *    </div>
 * </Wrapper>
 */
export const Wrapper = ({ children, direction }: WrapperProps) => {
	return (
		<ThemeProvider theme={{ direction }}>
			<WrapStyled className="wrap">{children}</WrapStyled>
		</ThemeProvider>
	);
};
