import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Link } from 'ui-library/components/atoms/Link';
import { Title } from 'ui-library/components/atoms/FontScale/Title';
import { Wrapper } from 'ui-library/components/layout/Wrapper';
import { HeaderSC, CtaContainer } from './style';
import { HeaderProps } from './types';
import { Logo } from 'ui-library/components/atoms/Logo';
import { useWindowWidth } from 'ui-library/hooks/useWindowWidth';
import { LinkInputProps } from 'ui-library/components/atoms/Link/types';
import LocaleSwitcher from 'ui-library/components/molecules/LocaleSwitcher';
import clsx from 'clsx';
import { isMobile } from 'ui-library/lib/isMobile';
import { Skeleton } from 'ui-library/components/layout/Skeleton';
import { HeaderSkeleton } from './skeleton';
import { useCrmData } from 'statics/hooks/useCrmData';

/**
 * Component for showing Header.
 * @component
 * @param variant currently navbar has only DEFAULT variant
 * @param nav {Array} Array of objects each with: href {string}, title {string} and target {boolean}
 * @example
 *   <Navbar variant="DEFAULT" nav=[{title: 'Contact', href: '/contact', target: true}]/>
 */
export const Header = ({
	image,
	linkLogo,
	title,
	variant,
	cta,
	isLoading = false,
}: HeaderProps) => {
	const [scrolled, setScrolled] = useState(false);
	const windowWidth = useWindowWidth();
	const isMobileView = isMobile(windowWidth);
	const { data: crmData } = useCrmData();
	if (!crmData) {
		throw new Error('CRM data not found');
	}
	const {
		productType: { availableLanguages },
	} = crmData;
	const hasLocales = availableLanguages.length > 1;

	/**
	 * Adds & removes class to have Fixed Position Header (Bottom) on scrolling down
	 * @listens window#scroll
	 */
	const scrollListener = () => {
		setScrolled(window.scrollY >= 200);
	};

	useEffect(() => {
		scrollListener();
		window.addEventListener('scroll', scrollListener);
	}, []);

	if (isLoading)
		return <Skeleton renderSkeleton={() => <HeaderSkeleton />} />;

	return (
		<ThemeProvider theme={{ variant }}>
			<HeaderSC
				className={clsx('HeaderSC', { scrolled })}
				id="header">
				<Wrapper direction="">
					<HeaderLogo
						href={linkLogo}
						image={image}
						title={title}
					/>
					{hasLocales && isMobileView && !scrolled ? (
						<LocaleSwitcher />
					) : (
						<HeaderCTA {...cta} />
					)}
				</Wrapper>
			</HeaderSC>
		</ThemeProvider>
	);
};

const HeaderLogo = ({
	href = '',
	image,
	title,
}: {
	href: string;
} & Pick<HeaderProps, 'image' | 'title'>) => {
	return (
		<div className="logo">
			<Link href={href} variant="unstyled">
				{image.src !== '' ? (
					<Logo image={image} />
				) : (
					<Title
						size="XL"
						variant="dark"
						headTag={title.headTag}>
						{title.text}
					</Title>
				)}
			</Link>
		</div>
	);
};

const HeaderCTA = ({ href, text, variant, size }: LinkInputProps) => {
	return (
		text &&
		href && (
			<CtaContainer>
				<Link variant={variant} size={size} href={href}>
					{text}
				</Link>
			</CtaContainer>
		)
	);
};
