import { FC } from 'react';
import { Accordion } from './organisms/Accordion';
import type { AccordionProps } from './organisms/Accordion/types';
import { Banner } from './organisms/Banner';
import type { BannerProps } from './organisms/Banner/types';
import { Cookies } from './organisms/Cookies';
import type { CookiesProps } from './organisms/Cookies/types';
import { CountryCards } from './organisms/CountryCards';
import type { CountryCardsProps } from './organisms/CountryCards/types';
import { Disclaimer } from './organisms/Disclaimer';
import type { DisclaimerProps } from './organisms/Disclaimer/types';
import { Footer } from './organisms/Footer';
import type { FooterProps } from './organisms/Footer/types';
import { Header } from './organisms/Header';
import type { HeaderProps } from './organisms/Header/types';
import { InfoBox } from './organisms/InfoBox';
import type { InfoBoxProps } from './organisms/InfoBox/types';
import { Jumbotron } from './organisms/Jumbotron';
import type { JumboProps } from './organisms/Jumbotron/types';
import { Navbar } from './organisms/Navbar';
import type { NavbarProps } from './organisms/Navbar/types';
import { Steps } from './organisms/Steps';
import type { StepsProps } from './organisms/Steps/types';
import { Table } from './organisms/Table';
import type { TableProps } from './organisms/Table/types';
import { InfoBlock } from './organisms/InfoBlock';
import type { InfoBlockProps } from './organisms/InfoBlock/types';

export interface OrganismsMapperProps {
	Accordion: FC<AccordionProps>;
	Banner: FC<BannerProps>;
	Cookies: FC<CookiesProps>;
	CountryCards: FC<CountryCardsProps>;
	Disclaimer: FC<DisclaimerProps>;
	Footer: FC<FooterProps>;
	Header: FC<HeaderProps>;
	InfoBox: FC<InfoBoxProps>;
	Jumbotron: FC<JumboProps>;
	Navbar: FC<NavbarProps>;
	Steps: FC<StepsProps>;
	Table: FC<TableProps>;
	InfoBlock: FC<InfoBlockProps>;
}

export const OrganismsMapper: OrganismsMapperProps = {
	Accordion,
	Banner,
	Cookies,
	CountryCards,
	Disclaimer,
	Footer,
	Header,
	InfoBox,
	Jumbotron,
	Navbar,
	Steps,
	Table,
	InfoBlock,
};
