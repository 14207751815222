import React from 'react';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import Select, {
	ControlProps,
	StylesConfig,
	components,
	createFilter,
} from 'react-select';
import { StyledOptions, StyledOptionsName } from './style';
import { CountryCardSelectProps } from './types';
import { FiSearch } from 'react-icons/fi';

export const CountryCardSelect = ({
	cardUrl,
	countries,
	placeholder,
}: CountryCardSelectProps) => {
	const router = useRouter();

	const customStyles: StylesConfig = {
		control: () => ({
			border: '1px solid #666666',
			padding: '10px',
			fontFamily: 'Arial',
			color: '#666666',
			width: '300px',
			display: 'inline-flex',
			justifyContent: 'start',
			alignItems: 'center',
			svg: {
				color: 'black',
				display: 'inline-block',
				marginRight: '10px',
				fontSize: '1.1em',
			},
		}),
		valueContainer: () => ({
			alignItems: 'center',
			display: 'inline-flex',
		}),
		dropdownIndicator: () => ({
			display: 'none',
		}),
		indicatorSeparator: () => ({
			display: 'none',
		}),
	};

	const customControl = ({
		children,
		...props
	}: ControlProps<any, false>) => (
		<components.Control {...props}>
			<FiSearch />
			{children}
		</components.Control>
	);

	const customFilter = createFilter({
		stringify: (option) =>
			`${(option.data as { name: string }).name}`,
	});

	function handleChange(option: unknown): void {
		if (
			typeof option === 'object' &&
			option !== null &&
			'value' in option &&
			typeof option.value === 'string'
		) {
			router.push(
				`${cardUrl}?option=${option.value.toUpperCase()}`,
			);
		} else {
			console.error('Selected option is empty or not valid.');
		}
	}

	return (
		<Select
			onChange={handleChange}
			filterOption={customFilter}
			components={{
				Control: customControl,
			}}
			options={countries.map(({ name, code, isoCode }) => {
				const src = `/common/flags/${code.toLowerCase()}.svg`;
				return {
					id: isoCode,
					name,
					value: code.toUpperCase(),
					label: (
						<>
							<StyledOptions>
								<Image
									unoptimized
									src={src}
									width={25}
									height={25}
									alt=""
								/>
								<StyledOptionsName>
									{name}
								</StyledOptionsName>
							</StyledOptions>
						</>
					),
				};
			})}
			placeholder={placeholder}
			styles={customStyles}
		/>
	);
};
