import React from 'react';
import { ThemeProvider } from 'styled-components';
import { LinkContent } from './style';
import { LinkInputProps } from './types';
import { useLocale } from 'next-intl';
import { Skeleton } from 'ui-library/components/layout/Skeleton';
import { LinkSkeleton } from './skeleton';
import { isAbsoluteUrl } from 'ui-library/lib/isAbsoluteUrl';

/**
 * Component to build a Link.
 * @param props Contains Link props: theme, size, children and href
 * @returns
 */
export const Link = ({
	children,
	href = '',
	text = '',
	target,
	rel,
	size = 'S',
	variant = 'default',
	isLoading = false,
}: LinkInputProps) => {
	const locale = useLocale();

	href = isAbsoluteUrl(href) ? href : `/${locale}${href}`;

	if (isLoading)
		return <Skeleton renderSkeleton={() => <LinkSkeleton />} />;

	const renderChildren = text && !children ? text : children;
	return (
		<ThemeProvider theme={{ variant, size }}>
			<LinkContent href={href} target={target} rel={rel}>
				{renderChildren}
			</LinkContent>
		</ThemeProvider>
	);
};
