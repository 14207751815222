import styled, { css } from 'styled-components';
import { media } from 'ui-library/lib/breakpoints';
import { TableLayout, TableSC } from '../Table/style';
import { FiCheck, FiX } from 'react-icons/fi';
import { BodyContent } from 'ui-library/components/atoms/FontScale/Body/style';
import { HEADER_HEIGHT } from '../Header/style';

export const Icon = css`
	stroke-width: 4;
	width: 1.2em;
	height: 1.2em;
`;

export const CheckIcon = styled(FiCheck)`
	${Icon}
	color: var(--color-success-_700);
`;

export const CrossIcon = styled(FiX)`
	${Icon}
	color: var(--color-error-_500);
`;

export const TLayout = styled(TableLayout)`
	border-collapse: separate;
	& > thead:first-child > tr:first-child > th {
		border-top: 1px solid var(--color-primary-_900);
	}
`;

export const TContainer = styled(TableSC)``;

export const THead = styled.thead`
	text-align: center;
	${media.smMax`
        width: 66px;
        padding: 12px 4px;
    `}
`;

export const THeadRow = styled.tr`
	height: 80px;
	text-align: center;
`;

export const THeadCell = styled.th`
	background-color: var(--color-primary-_100);
	border-bottom: 1px solid var(--color-primary-_900);
	border-right: 1px solid var(--color-primary-_900);
	line-height: 1.5;
	padding: 20px 16px;
	position: sticky;
	top: 0;
	vertical-align: middle;
	z-index: 1;

	${media.mdMin`
		top: ${HEADER_HEIGHT};
	`}

	${BodyContent} {
		font-size: var(--font-body-size-XS-mobile);
		font-weight: 700;
		line-height: 12px;
		letter-spacing: 0.6px;
		p {
			margin-bottom: 0;
		}
		${media.mdMin`
			font-size: inherit;
			line-height: inherit;
			letter-spacing: inherit;
		`}
	}

	&:first-child {
		width: 652px;
		text-align: left;
		border-left: 1px solid var(--color-primary-_900);
	}

	&:nth-child(2) {
		width: 121px;
	}
	&:last-child {
		width: 189px;
		box-shadow:
			5px 0 8px -5px rgb(102 102 102 / 60%),
			-5px 0 8px -5px rgb(102 102 102 / 60%);
	}
`;

export const TBody = styled.tbody`
	text-align: center;
	&:first-child {
		text-align: left;
		border-left: 1px solid var(--color-primary-_900);
	}
	&:last-child {
		background-color: var(--color-success-_100);
	}
`;

export const TBodyRow = styled.tr`
	text-align: center;
	&:nth-child(odd) {
		background-color: var(--color-basic-white);
	}
	&:nth-child(even) {
		background-color: var(--color-neutral-_100);
	}
	&:first-child {
		text-align: left;
		border-left: 1px solid var(--color-primary-_900);
	}
`;

const Cell = css`
	border-bottom: 1px solid var(--color-primary-_900);
	border-right: 1px solid var(--color-primary-_900);
	line-height: 1.5;
	padding: 8px 21px;
	text-align: center;
	vertical-align: middle;

	&:first-child {
		border-left: 1px solid var(--color-primary-_900);
		text-align: left;
	}

	&:last-child {
		background-color: var(--color-success-_100);
		box-shadow: 0 3px 8px 0 rgb(102 102 102 / 60%);
	}

	${BodyContent} {
		font-size: var(--font-body-size-XS-mobile);
		line-height: 12px;
		letter-spacing: 0.6px;
		p {
			margin-bottom: 0;
		}
		${media.mdMin`
			font-size: inherit;
			line-height: inherit;
			letter-spacing: inherit;
		`}
	}

	${media.mdMin`
		padding: 16px;
	`}
`;

export const TBodyRowHeader = styled.th`
	${Cell}
`;

export const TBodyCell = styled.td`
	${Cell}
`;
