import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { InfoBoxSkeletonSC } from './style.skeleton';

export const InfoBoxSkeleton = (props: IContentLoaderProps) => {
	return (
		<InfoBoxSkeletonSC speed={2} width={960} height={61} {...props}>
			<rect x={0} y={0} width="100%" height={61} />
			);
		</InfoBoxSkeletonSC>
	);
};

export default InfoBoxSkeleton;
