import { IContentLoaderProps } from 'react-content-loader';
import { CountryCardSkeletonSC } from './style.skeleton';

export const CountryCardSkeleton = (props: IContentLoaderProps) => (
	<CountryCardSkeletonSC
		speed={2}
		width="100%"
		height={579}
		backgroundColor="#f3f3f3"
		foregroundColor="#ece0e0"
		{...props}>
		<rect x="0" y="0" width="100%" height="100%" />
	</CountryCardSkeletonSC>
);
