import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import ButtonNucleus from './ButtonNucleus';

import { filled } from './style.filled';
import { outlined } from './style.outlined';
import { text } from './style.text';
import { elevated } from './style.elevated';

const size = theme('size', {
	XL: css`
		font-size: var(--font-body-size-XL-mobile);
		padding: var(--spacing-_32) var(--spacing-_40);
		font-weight: bold;
	`,
	L: css`
		font-size: var(--font-body-size-XL-mobile);
		line-height: 1.4444;
		padding: var(--spacing-_24) var(--spacing-_32);
	`,
	M: css`
		font-size: var(--font-body-size-M-mobile);
		padding: var(--spacing-_16) var(--spacing-_24);
	`,
	S: css`
		font-size: var(--font-body-size-S-mobile);
		padding: var(--spacing-_8) var(--spacing-_16);
	`,
});

const variant = theme('variant', {
	filled,
	outlined,
	text,
	elevated,
});

export const ButtonContent = styled(ButtonNucleus)`
	border: none;
	border-radius: 4px;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
	&:first-child {
		margin-left: 0;
	}
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	${size}
	${variant}
`;
