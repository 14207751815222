import { FC } from 'react';
import Image from 'next/image';

import { Body, Title } from './atoms/FontScale';
import { Button } from './atoms/Button';
import { Link } from './atoms/Link';

import type { BodyProps } from './atoms/FontScale/Body/types';
import type { ButtonProps } from './atoms/Button/types';
import type { LinkInputProps } from './atoms/Link/types';
import type { TitleProps } from './atoms/FontScale/Title/types';

export interface AtomsMapperProps {
	Body: FC<BodyProps>;
	Button: FC<ButtonProps>;
	Image: FC<any>; // FIXME: It should be FC<ImageProps> but it keep throwing a TS error ('never' type).
	Link: FC<LinkInputProps>;
	Title: FC<TitleProps>;
}
export const AtomsMapper: AtomsMapperProps = {
	Body,
	Button,
	Image,
	Link,
	Title,
};
