import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { InfoBlockSkeletonSC } from './style.skeleton';
import { Variant } from './types';

interface InfoBlockSkeletonProps extends IContentLoaderProps {
	columns: number;
	variant: keyof typeof Variant;
}

export const InfoBlockSkeleton = ({
	columns,
	variant,
	...props
}: InfoBlockSkeletonProps) => {
	const colSize = 100 / columns;
	const rectSize = colSize - 5;

	return (
		<>
			<InfoBlockSkeletonSC.Mobile
				speed={2}
				height={variant === 'default' ? 248 : 635 - 26}
				backgroundColor="#f3f3f3"
				foregroundColor="#ece0e0"
				{...props}>
				{[...Array(columns)].map((_, i) => {
					return (
						<rect
							key={i}
							x={0}
							y={`${colSize * i}%`}
							width="100%"
							height={`${rectSize}%`}
						/>
					);
				})}
			</InfoBlockSkeletonSC.Mobile>
			<InfoBlockSkeletonSC.Desktop
				speed={2}
				// FIXME: Height ñapa. the "Advantages" infoblock uses 'default'
				// variant so we reduce height for this case. It's not correct but
				// do the trick for now.
				height={variant === 'default' ? 50 : 370}
				backgroundColor="#f3f3f3"
				foregroundColor="#ece0e0"
				{...props}>
				{[...Array(columns)].map((_, i) => {
					return (
						<rect
							key={i}
							x={`${colSize * i}%`}
							y="0"
							width={`${rectSize}%`}
							height="370"
							transform="translate(20,0)"
						/>
					);
				})}
			</InfoBlockSkeletonSC.Desktop>
		</>
	);
};

export default InfoBlockSkeleton;
