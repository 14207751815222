import React, { PropsWithChildren } from 'react';
import { ButtonNucleusProps } from './types';

const ButtonNucleus = ({
	children,
	type = 'button',
	icon,
	disabled = false,
	form,
	className,
	onClick,
}: PropsWithChildren<ButtonNucleusProps>) => (
	<button
		type={type}
		disabled={disabled}
		form={form}
		className={className}
		onClick={onClick}>
		{icon && <span className="btn__icon">{icon}</span>}
		{children}
	</button>
);

export default ButtonNucleus;
