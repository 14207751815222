import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { Title, Body } from 'ui-library/components/atoms/FontScale';
import { media } from 'ui-library/lib/breakpoints';

/**
 * Icon
 * @todo FIXME: Usar styled(Image) no funciona bien.
 */
export const IconSC = styled.img`
	grid-area: icon;
	align-self: start;
	width: auto;
	height: auto;
`;

/**
 * Title
 * @variation Default
 */
export const TitleSC = styled(Title)`
	grid-area: title;
	text-align: start;
	margin: 20px 0 0;
	${media.mdMin`
		margin: 0;	
	`}
`;

/**
 * Description
 */
export const DescriptionSC = styled(Body).attrs({ size: 'M' })`
	grid-area: description;
	text-align: start;
	p {
		line-height: 1.3;
		letter-spacing: 0.25px;
		margin: 0;
	}
`;

/**
 * Foot note.
 * @variation Card
 */
export const FootNoteSC = styled(Title).attrs({
	size: 'XS',
})`
	grid-area: footnote;
	text-align: start;
	align-self: end;
	p {
		letter-spacing: 0.25px;
		margin: 0;
		color: var(--color-primary-_900);
	}
`;

const variant = theme('variant', {
	card: css`
		justify-items: center;
		padding: var(--spacing-_24) var(--spacing-_16);
		row-gap: var(--spacing-_16);
		width: fit-content;
		grid-template-areas: ${({ theme: { direction } }): string =>
			direction === 'row'
				? `'icon description'
				'icon footnote'`
				: `'icon'
				'description'
				'footnote'`};

		${IconSC} {
			width: 96px;
			height: 96px;
		}

		${media.mdMin`
			border-radius: 4px;
			border: 2px solid var(--color-neutral-_500);
			max-width: ${({
				theme: { direction },
			}: {
				theme: { direction: string };
			}): string => (direction === 'row' ? 'none' : '240px')};
			max-height: 338px;
			margin: 0 var(--spacing-_24);
		`}
	`,
	default: css``,
});

const direction = theme('direction', {
	row: css`
		gap: var(--spacing-_8) var(--spacing-_16);
		grid-template-columns: 1fr;
		grid-template-rows: repeat(2, auto);
		grid-template-areas:
			'icon'
			'title'
			'description';

		${({ theme: { variant } }) =>
			variant === 'default' &&
			media.mdMin`
				grid-template-columns: auto 1fr;
				grid-template-areas:
					'icon title'
					'icon description';
		`}
	`,
	column: css`
		row-gap: var(--spacing-_8);
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-template-areas:
			'icon'
			'title'
			'description';
		${media.mdMin`
			grid-template-rows: repeat(3, 1fr);
		`}
	`,
});

/**
 * Grid
 */
export const GridSC = styled.div`
	display: grid;
	grid-auto-flow: row;
	padding: var(--spacing-_16) 0px;
	align-self: flex-start;

	${direction}
	${variant}
`;
