import React, { useContext, ChangeEvent } from 'react';
import { FiSearch } from 'react-icons/fi';
import { FilterContext } from 'statics/services/CrmDataContext';
import { SearchBoxContent } from './style';
import { FilterProps, FilterValue } from './types';
import { useTranslations } from 'next-intl';
import { base64Encode } from 'statics/lib/base64Encoder';

/**
 * Filter input component.
 *
 * NOTE: This component is based on a unique use-case (filtering CountryCards).
 * Please make it dynamic if in the future is used for another collections.
 *
 * @component
 * @param dataSource Data object to filter out.
 * @returns Filter input box component.
 */
export const Filter = ({
	dataSource,
	placeholder = 'Search your country...',
}: FilterProps) => {
	const [, setResults] = useContext(FilterContext);

	const t = useTranslations();

	const handleFilterChange = (
		event: ChangeEvent<HTMLInputElement>,
	) => {
		const keyword = event.target.value;
		if (keyword !== '') {
			const results: Array<Object> = dataSource.filter(
				(value: FilterValue) =>
					t(base64Encode(value.name))
						.toLowerCase()
						.includes(keyword.toLowerCase()),
			);
			setResults(results);
		} else {
			setResults(dataSource);
		}
	};

	return (
		<SearchBoxContent>
			<FiSearch />
			<input
				type="search"
				onChange={handleFilterChange}
				className="input"
				placeholder={placeholder}
			/>
		</SearchBoxContent>
	);
};
