import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import {
	AccordionSC,
	AccordionContent,
	AccordionHeader,
	AccordionArrow,
} from './style';
import { AccordionProps } from './types';
import { Title } from 'ui-library/components/atoms/FontScale';
import { Body } from 'ui-library/components/atoms/FontScale';
import { Link } from 'ui-library/components/atoms/Link';
import AccordionSkeleton from './skeleton';
import { Skeleton } from 'ui-library/components/layout/Skeleton';

export const Accordion = ({
	addQuestion,
	isLoading = false,
}: AccordionProps) => {
	const [activeIndex, setActiveIndex] = useState<number | null>(0);

	return (
		<>
			<div id="accordion">
				{addQuestion?.map(({ body, title, element }, index) => {
					const [questionButton] = element ?? [];
					const isOpen = index === activeIndex;

					const toggleQuestion = () => {
						setActiveIndex(isOpen ? null : index);
					};

					if (isLoading)
						return (
							<Skeleton
								key={`${title.text}_${index}`}
								renderSkeleton={() => (
									<AccordionSkeleton order={index} />
								)}
							/>
						);

					return (
						<AccordionSC key={title.text}>
							<AccordionHeader
								onClick={toggleQuestion}
								tabIndex={0}
								role="button"
								onKeyUp={toggleQuestion}>
								<Title
									size="XS"
									variant={
										(title.variant ??= 'light')
									}
									headTag="h3">
									{title.text}
								</Title>
								<AccordionArrow>
									{isOpen ? (
										<FiChevronUp
											color="var(--color-primary-_500)"
											size="32px"
										/>
									) : (
										<FiChevronDown
											color="var(--color-primary-_500)"
											size="32px"
										/>
									)}
								</AccordionArrow>
							</AccordionHeader>
							{isOpen && (
								<AccordionContent>
									<Body size="L" variant="light">
										{body.text}
									</Body>
									{questionButton && (
										<Link {...questionButton} />
									)}
								</AccordionContent>
							)}
						</AccordionSC>
					);
				})}
			</div>
		</>
	);
};
