import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { TableSkeletonSC } from './style.skeleton';

export const TableSkeleton = (props: IContentLoaderProps) => (
	<TableSkeletonSC speed={2} width="100%" height={990} {...props}>
		<rect x="0" y="0" width="100%" height={958} />
	</TableSkeletonSC>
);

export default TableSkeleton;
