import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { media } from 'ui-library/lib/breakpoints';

const HEADER_HEIGHT = '72px';

const variants = theme('variant', {
	default: css``,
	logoWithText: css`
		background-color: var(--color-neutral-_100);
		div.logo {
			a {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				flex-wrap: nowrap;
				text-decoration: none;
				h1,
				h2,
				h3,
				h4 {
					display: block;
					margin: 0 0 0 var(--spacing-_16);
					color: var(--color-primary-_900);
					max-width: 180px;
					font-size: var(--font-body-size-S-mobile);
					line-height: 1.5;
					font-weight: 400;
				}
			}
		}
		${media.smMax`
			padding: var(--spacing-_8) 0;
			div.logo {
					a {
						flex-direction: column;
						align-items: flex-start;
						h1,
						h2,
						h3,
						h4 {
							margin: var(--spacing-_8) 0 0 0;
						}
					}
				}
		`}
	`,
});

const HeaderSC = styled.div`
	background-color: var(--color-primary-_900);
	z-index: 2;
	width: 100%;
	position: fixed;
	height: ${HEADER_HEIGHT};
	box-shadow: 1px 1px 3px rgb(0 0 0 / 50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	bottom: unset;
	top: 0;
	left: 0;
	${media.smMax`
		&.scrolled {
			box-shadow: 0px -2px 15px 0px rgba(67, 69, 139, 0.1);
			bottom: 0;
			top: unset;
		}	
	`}

	& > .wrap {
		height: 40px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0 16px;
		align-items: stretch;
		flex-wrap: nowrap;

		${media.mdMin`
			padding: 0 32px;
		`}

		.logo {
			h1 {
				display: none;
				margin-top: 0;
				margin-bottom: 0;
				max-width: 220px;
				text-align: left;
				margin: 0;
				${media.smMax`
					font-size: 12px;	
				`}
			}
		}
	}
	${variants}
`;

const CtaContainer = styled.div`
	align-content: center;
`;

export { HeaderSC, HEADER_HEIGHT, CtaContainer };
