import React from 'react';
import Image from 'next/image';
import { useWindowWidth } from 'ui-library/hooks/useWindowWidth';
import type { LogoProps } from './types';
import { isMobile } from 'ui-library/lib/isMobile';

export const Logo = ({ image }: LogoProps) => {
	const windowWidth = useWindowWidth();
	const isMobileView = isMobile(windowWidth);

	const { alt, title } = image;
	const height = image.height ?? '40';
	const width = isMobileView ? '110' : image.width ?? '250';
	const src = isMobileView
		? image.src.toString().replace('lg', 'xs')
		: image.src;

	return (
		<Image
			unoptimized
			src={src}
			alt={alt}
			title={title}
			width={width}
			height={height}
			quality={100}
		/>
	);
};
