import { IContentLoaderProps } from 'react-content-loader';
import { HeaderSkeletonSC } from './style.skeleton';

export const HeaderSkeleton = (props: IContentLoaderProps) => (
	<HeaderSkeletonSC
		speed={2}
		viewBox="0 0 960 72"
		backgroundColor="#f3f3f3"
		foregroundColor="#ece0e0"
		{...props}>
		<rect x="0" y="16" rx="3" ry="3" width={250} height="40" />
		<rect x="828" y="16" rx="3" ry="3" width={100} height="40" />
	</HeaderSkeletonSC>
);

export default HeaderSkeleton;
