import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const HeaderSkeletonSC = styled(ContentLoader)`
	width: 100%;
	height: var(--spacing-_72);
	background-color: var(--color-neutral-_50);
	position: fixed;
	z-index: 2;
`;
