import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { media } from 'ui-library/lib/breakpoints';

const Desktop = styled(ContentLoader)`
	width: 100%;
	display: none;
	${media.mdMin`
        display: block;
    `}
`;

const Mobile = styled(ContentLoader)`
	width: 100%;
	${media.smMin`
     display: none;
    `}
`;

export const StepsSkeletonSC = {
	Mobile,
	Desktop,
};
