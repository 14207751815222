import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { breakpoints, media } from 'ui-library/lib/breakpoints';

const wrappDirection = theme('direction', {
	row: css`
		${media.lgMin`
			flex-direction: row;
		`}
	`,
	column: css`
		${media.lgMin`
			flex-direction: column;
		`}
	`,
});

const WrapStyled = styled.div`
	${wrappDirection}
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 100%;
	padding: 0;
	width: 100%;
	transition-property: all;
	transition-duration: var(--transition-duration);
	transition-timing-function: var(--transition-timingFunction);
	transition-delay: 0s;
	justify-content: center;
	align-items: center;
	${media.mdMin`
		align-items: normal;	
	`}
	${media.lgMin`
		max-width: ${breakpoints.TABLET_LANDSCAPE_MIN_WIDTH};	
	`}
`;
export { WrapStyled };
