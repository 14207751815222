'use client';
import { ThemeProvider } from 'styled-components';
import {
	AtomsMapper,
	AtomsMapperProps,
	ComponentsMapper,
	ComponentsMapperProps,
} from 'ui-library/components';
import capitalize from 'ui-library/lib/capitalize';
import { SectionContent } from './style';
import { Wrapper } from '../Wrapper';
import { SectionProps } from './types';
import { useSection } from './useSection';

/**
 * Component to contein dynamic blocks of information.
 * @component
 * @param background Background variant.
 * @param direction Inner items flow direction.
 * @param width Section width: full | container.
 * @param add_element Children elements
 * @returns Section block containing an elements collection inside a Wrapper block.
 */
export const Section = ({
	id,
	background: variant,
	direction,
	width,
	border,
	add_element: { element: elements },
	textAlign,
	previewMode = false,
}: SectionProps) => {
	const { processedElements, isLoading } = useSection({
		elements,
		sectionId: id,
	});

	const renderElements = isLoading ? elements : processedElements;
	if (isLoading && variant === 'dark') variant = 'medium';

	return (
		<ThemeProvider theme={{ variant, width, textAlign, border }}>
			<SectionContent className="Section">
				<Wrapper direction={direction}>
					{renderElements.map((element, index) => {
						// Print atoms
						if (
							Object.prototype.hasOwnProperty.call(
								element,
								'type',
							)
						) {
							const key = `atom-id-${index}`;
							const keyComponent = capitalize(
								element.type,
							);
							const Atom =
								AtomsMapper[
									keyComponent as keyof AtomsMapperProps
								];
							return (
								<Atom
									key={key}
									{...element}
									isLoading={isLoading}
								/>
							);
						}

						// Print Components
						return element.component?.add_type?.map(
							(add_type: any, index) => {
								const key = `${element.component?.id}-${index}`;
								const keyComponent = capitalize(
									add_type.type,
								);
								const Component =
									ComponentsMapper[
										keyComponent as keyof ComponentsMapperProps
									];
								return (
									<Component
										key={key}
										{...add_type}
										previewMode={previewMode}
										isLoading={isLoading}
									/>
								);
							},
						);
					})}
				</Wrapper>
			</SectionContent>
		</ThemeProvider>
	);
};
