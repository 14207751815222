/**
 * Capitalize given string.
 * @param s String to modify.
 * @returns Param string capitalized (ONLY first word).
 */
export default function capitalize(s: string): string {
	return typeof s !== 'string'
		? ''
		: s.charAt(0).toUpperCase() + s.slice(1);
}
