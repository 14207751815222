import { css } from 'styled-components';

export const text = css`
	border: none;
	color: var(--color-primary-_500);
	background-color: transparent;
	&:hover:not([disabled]) {
		&::after {
			background-color: var(--color-primary-_500);
			opacity: 0.04;
		}
	}
	&:focus {
		&::after {
			background-color: var(--color-primary-_500);
			opacity: 0.12;
		}
	}
	&:active:not([disabled]) {
		&::after {
			background-color: var(--color-primary-_500);
			opacity: 0.1;
		}
	}
	&:disabled {
		color: rgba(0, 0, 0, 0.38);
	}
`;
