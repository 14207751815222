import React from 'react';
import Image from 'next/image';
import { CountryCardContainer } from './style';
import { CountryCardProps } from './types';
import { useTranslations } from 'next-intl';
import { base64Encode } from 'statics/lib/base64Encoder';

export const CountryCard = ({ name, src, href }: CountryCardProps) => {
	const t = useTranslations();
	return (
		<CountryCardContainer href={href}>
			<Image
				unoptimized
				src={src}
				className="img-fluid"
				alt={name}
				width={25}
				height={25}
			/>
			{t(base64Encode(name))}
		</CountryCardContainer>
	);
};
