import React, { useEffect, useState } from 'react';
import { CookiesContent } from './style';
import { Body } from 'ui-library/components/atoms/FontScale/Body';
import { CookiesProps } from './types';
import CookieManager from 'js-cookie';
import Button from 'ui-library/components/atoms/Button';

/**
 * Cookies banner component.
 * Once you click on button it hides and generates a cookie to keep it hidden.
 * @component
 * @example
 *   <Cookies />
 */
export const Cookies = ({ text }: CookiesProps) => {
	const [showMe, setShowMe] = useState<boolean>(false);

	/**
	 * 'I Agree' onClick handler.
	 * @fires setShowMe - State setter
	 */
	const acceptCookies = (): void => {
		CookieManager.set('cookiePopUp', '1');
		setShowMe(false);
	};

	useEffect((): void => {
		setShowMe(CookieManager.get('cookiePopUp') !== '1');
	}, []);

	return (
		<>
			{showMe && (
				<CookiesContent>
					<Body size="S" variant="dark">
						{text}
					</Body>
					<Button
						size="S"
						variant="filled"
						onClick={acceptCookies}>
						I agree
					</Button>
				</CookiesContent>
			)}
		</>
	);
};
