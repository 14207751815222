import React from 'react';
import { Body } from 'ui-library/components/atoms/FontScale/Body';
import { RegularTableProps } from './types';
import {
	TContainer,
	TLayout,
	THead,
	TBody,
	THeadRow,
	THeadCell,
	TBodyCell,
	TBodyRow,
	TBodyRowHeader,
} from './style';

export const RegularTable = ({
	headers,
	rows,
	highlight = false,
}: RegularTableProps) => {
	return (
		<TContainer>
			<TLayout>
				<THead>
					<THeadRow>
						{headers?.map(({ body: { text } }) => (
							<THeadCell
								key={text}
								$highlight={highlight}>
								<Body size="L" variant="light">
									{text}
								</Body>
							</THeadCell>
						))}
					</THeadRow>
				</THead>
				<TBody>
					{rows?.map((row, i) => (
						<TBodyRow
							key={`tbody-tr-${i}`}
							$highlight={highlight}>
							{row.element?.map(({ text }) =>
								i === 0 ? (
									<TBodyRowHeader
										scope="row"
										key={text}
										$highlight={highlight}>
										<Body size="L" variant="light">
											{text}
										</Body>
									</TBodyRowHeader>
								) : (
									<TBodyCell
										key={text}
										$highlight={highlight}>
										<Body size="L" variant="light">
											{text}
										</Body>
									</TBodyCell>
								),
							)}
						</TBodyRow>
					))}
				</TBody>
			</TLayout>
		</TContainer>
	);
};
