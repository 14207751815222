import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { BodyContent } from 'ui-library/components/atoms/FontScale/Body/style';
import { media } from 'ui-library/lib/breakpoints';

const NAVBAR_HEIGHT = '64px';

const navVariants = theme('variant', {
	DEFAULT: css`
		${media.smMax`
			.wrap {
				padding: 0;
			}	
		`}
	`,
});

const NavbarSC = styled.div`
	height: ${NAVBAR_HEIGHT};
	display: flex;
	background-color: var(--color-primary-_50);
	align-items: center;
	width: 100%;
	${navVariants}
`;

const MenuNavList = styled.ul`
	appearance: none;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: var(--spacing-_24);
	${media.smMax`
		justify-content: space-around;
		align-items: center;
	`}
`;

const MenuNavElement = styled.li`
	appearance: none;
	list-style: none;
	height: ${NAVBAR_HEIGHT};

	a {
		height: 100%;
		display: flex;
		align-items: center;
		padding: 0 12px;
		p {
			margin: 0;
		}
	}

	padding: 0;
	text-align: center;

	.current ${BodyContent} {
		border-bottom: 4px solid var(--color-primary-_900);
		padding-bottom: 4px;
	}

	${media.mdMin`
		text-align: -webkit-match-parent;
		&:hover {
			background-color: var(--color-primary-_100);
		}
		a {
			padding: 0 16px;
		}
		.current ${BodyContent} {
			border: none;
			padding-bottom: 0;
		}
	`}
`;

const LangNavElement = styled.li`
	position: relative;
`;

const LangDropdown = styled.ul`
	display: none;
	position: absolute;
	bottom: -${NAVBAR_HEIGHT};
	left: 0;
	margin: 0;
	padding: 0;
	background: var(--color-primary-_50);
	&:hover {
		display: block;
	}
`;

const LangElement = styled.li``;

export {
	NavbarSC,
	MenuNavList,
	MenuNavElement,
	LangNavElement,
	LangDropdown,
	LangElement,
};
