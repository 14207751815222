import React from 'react';
import { NavbarSkeletonSC } from './style.skeleton';
import { IContentLoaderProps } from 'react-content-loader';

interface NavbarSkeletonProps extends IContentLoaderProps {
	hasLanguageSelector: boolean;
}

export const NavbarSkeleton = ({
	hasLanguageSelector,
	...props
}: NavbarSkeletonProps) => (
	<NavbarSkeletonSC speed={2} viewBox="0 0 960 64" {...props}>
		<rect x="860" y="16" rx="3" ry="3" width={100} height="32" />
		<rect x="750" y="16" rx="3" ry="3" width={100} height="32" />
		<rect x="640" y="16" rx="3" ry="3" width={100} height="32" />
		{hasLanguageSelector && (
			<rect
				x="530"
				y="16"
				rx="3"
				ry="3"
				width={100}
				height="32"
			/>
		)}
	</NavbarSkeletonSC>
);
