'use client';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiX, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Body } from 'ui-library/components/atoms/FontScale/Body';
import {
	DisclaimerContainer,
	DisclaimerContent,
	ReadMore,
} from './style';
import { DisclaimerProps } from './types';
import DisclaimerSkeleton from './skeleton';
import { Skeleton } from 'ui-library/components/layout/Skeleton';

/**
 * Component for showing Disclaimer banner inside Jumbotron (top) or on Footer (bottom).
 * @component
 * @example
 *   <Disclaimer body={}/>
 */
export const Disclaimer = ({
	body,
	previewMode,
	openedLabel = 'Read less',
	closedLabel = 'Read more',
	isLoading = false,
}: DisclaimerProps) => {
	const [showMe, setShowMe] = useState<boolean>(true);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [position, setPosition] = useState<string | undefined>(
		undefined,
	);
	const ref = useRef<HTMLDivElement | null>(null);

	const getDisclaimerPosition = useCallback(() => {
		const jumbotronElement =
			ref.current?.offsetParent?.previousElementSibling?.getElementsByClassName(
				'Jumbotron',
			);
		const currentPosition =
			(jumbotronElement?.length || 0) > 0 ? 'top' : 'bottom';
		if (position !== currentPosition) {
			setPosition(currentPosition);
		}
	}, []);

	const closeDisclaimer = () => setShowMe(false);

	useEffect(() => {
		if (previewMode) {
			getDisclaimerPosition();
		}
	});

	useEffect(() => {
		if (!previewMode) {
			getDisclaimerPosition();
		}
	}, []);

	if (isLoading)
		return (
			<Skeleton renderSkeleton={() => <DisclaimerSkeleton />} />
		);

	return (
		showMe && (
			<div ref={ref}>
				{position && (
					<DisclaimerContainer $position={position}>
						<DisclaimerContent $isOpen={isOpen}>
							<Body variant="dark" size="S">
								{body.text}
							</Body>
							<FiX
								title="Close disclaimer"
								size={16}
								color="white"
								onClick={closeDisclaimer}
							/>
						</DisclaimerContent>
						<ReadMore onClick={() => setIsOpen(!isOpen)}>
							{isOpen ? (
								<>
									{openedLabel}
									<FiChevronUp
										title="Show entire disclaimer text"
										size={14}
										color="white"
									/>
								</>
							) : (
								<>
									{closedLabel}
									<FiChevronDown
										title="Collapse disclaimer text"
										size={14}
										color="white"
									/>
								</>
							)}
						</ReadMore>
					</DisclaimerContainer>
				)}
			</div>
		)
	);
};
