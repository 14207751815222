'use client';
import React from 'react';
import { ThemeVarsProps } from 'statics/lib/types/variables';
import { Country } from 'ui-library/components/organisms/CountryCards/types';

type Price = {
	min: { amount: number; currency: string };
	max: { amount: number; currency: string };
};

type Prices = {
	official_price: Price;
	service_fee: Price;
	total_price: Price;
	non_refundable_amount: Price;
}[];

export type ProductType = {
	availableLanguages: { locale: string; name: string }[];
	eligibleCountries: Country[];
	emailFromName: string;
	freshchatCode?: string;
	slaMax: number;
	slaMin: number;
};

export type Site = {
	companyAddress: string;
	companyCif: string;
	companyName: string;
	companyPhone: string;
	googleTagManager?: string;
	infoEmail: string;
	morAddress: string;
	morCif: string;
	morName: string;
	morRegistry: any;
	prices: {
		service_fee: {
			max: {
				amount: number;
				currency: string;
			};
		};
	};
	site_url: string;
	sitePhone: string;
	url: string;
	visaEmail: string;
};

/*
 *
 * @export
 * @interface CrmData
 * */
export interface CrmData {
	productType: ProductType;
	site: Site;
}

export interface AppContextProps {
	hostUrl: string;
	themeVars: ThemeVarsProps;
}

export const AppContext = React.createContext({} as AppContextProps);

export default AppContext;
