import styled from 'styled-components';

const StyledOptions = styled.div`
	font-family: var(--font-body-family-regular);
	font-size: var(--font-body-size-L-mobile);
	text-align: left;
	height: 36px;
	line-height: 36px;
	padding: 0;
	margin: 0;
`;

const StyledOptionsName = styled.p`
	padding-left: 10px;
	display: inline-block;
	vertical-align: middle;
	font-weight: bold;
`;

export { StyledOptions, StyledOptionsName };
