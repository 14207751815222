import styled from 'styled-components';
import { BodyContent } from 'ui-library/components/atoms/FontScale/Body/style';
import { ButtonContent } from 'ui-library/components/atoms/Button/style';
import { media } from 'ui-library/lib/breakpoints';

const CookiesContent = styled.div`
	background: color-mix(
		in srgb,
		var(--color-basic-black) var(--opacity-emphasis-surface-high),
		transparent
	);
	display: flex;
	justify-content: center;
	letter-spacing: 0.2px;
	line-height: 21px;
	max-width: -webkit-fill-available;
	position: fixed;
	width: 100%;
	align-items: center;
	bottom: auto;
	padding: var(--spacing-_8) var(--spacing-_24);
	text-align: start;
	top: 0px;
	z-index: 3;

	${BodyContent} {
		width: auto;
		> p {
			color: var(--color-basic-white);
			margin-block-start: 0;
			margin-bottom: 0px;
			a {
				color: inherit;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	${ButtonContent} {
		margin: 0 0 0 var(--spacing-_16);
	}

	${media.mdMin`
		align-items: baseline;
		bottom: 0px;
		text-align: center;
		top: auto;
	`}
`;

export { CookiesContent };
