import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { DisclaimerSkeletonSC } from './style.skeleton';

export const DisclaimerSkeleton = (props: IContentLoaderProps) => (
	<DisclaimerSkeletonSC
		speed={2}
		width={960}
		height={67}
		backgroundColor="#f3f3f3"
		foregroundColor="#ece0e0"
		{...props}>
		<rect x="0" y="0" width="100%" height="67" />
	</DisclaimerSkeletonSC>
);

export default DisclaimerSkeleton;
