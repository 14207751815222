import styled from 'styled-components';
import Link from 'next/link';
import { media } from 'ui-library/lib/breakpoints';

export const CountryCardContainer = styled(Link)`
	flex-basis: 18.8%;
	gap: 10px;
	padding: 5px 10px;
	box-shadow: 0px 0px 4px 0px #cccccc;
	text-decoration: none;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	min-height: 55px;
	font-family: var(--font-body-family-regular);
	font-size: var(--font-body-size-M-mobile);
	font-weight: 600;
	text-align: left;
	color: color-mix(
		in srgb,
		var(--color-text-onSurface) var(--opacity-emphasis-surface-high),
		transparent
	);

	&:hover {
		box-shadow: 0px 2px 8px 0px #cccccc;
		transform: translateY(-4px);
	}

	${media.md`
		flex-basis: 23.5%;
	`}
`;
