import styled from 'styled-components';
import { BodyContent } from 'ui-library/components/atoms/FontScale/Body/style';
import { TitleContainer } from 'ui-library/components/atoms/FontScale/Title/style';
import { LinkContent } from 'ui-library/components/atoms/Link/style';

const AccordionArrow = styled.div`
	min-height: 32px;
	min-width: 32px;
`;

const AccordionHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0;
	padding: var(--spacing-_24) var(--spacing-_32);
	cursor: pointer;
	text-align: left;
	${TitleContainer} {
		margin: 0px;
	}
`;

const AccordionContent = styled.div`
	padding: var(--spacing-_24) var(--spacing-_32);
	p {
		margin-bottom: 0;
		text-align: left;
	}
	${BodyContent} + ${LinkContent} {
		margin-top: 16px;
	}
`;

const AccordionSC = styled.div`
	&:last-of-type {
		margin-bottom: var(--spacing-_32);
	}
	position: relative;
	margin: 5px 0 0 0;
	border: 1px solid var(--color-primary-_500);
	border-left-width: 8px;
	background-color: var(--color-basic-white);
	z-index: 0;
`;

export {
	AccordionSC,
	AccordionHeader,
	AccordionContent,
	AccordionArrow,
};
