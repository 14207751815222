import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { StepsSkeletonSC } from './style.skeleton';

export const StepsSkeleton = (props: IContentLoaderProps) => (
	<>
		<StepsSkeletonSC.Mobile
			speed={2}
			width="100%"
			height={340}
			backgroundColor="#f3f3f3"
			foregroundColor="#ece0e0"
			{...props}>
			<rect x="0" y="0" width="100%" height="80" />
			<rect x="0" y="130" width="100%" height="80" />
			<rect x="0" y="260" width="100%" height="80" />
		</StepsSkeletonSC.Mobile>
		<StepsSkeletonSC.Desktop
			speed={2}
			width="100%"
			height={96}
			backgroundColor="#f3f3f3"
			foregroundColor="#ece0e0"
			{...props}>
			<rect x="0" y="0" width="250" height="80" />
			<rect x="280" y="0" width="50" height="80" />
			<rect x="360" y="0" width="250" height="80" />
			<rect x="640" y="0" width="50" height="80" />
			<rect x="720" y="0" width="250" height="80" />
		</StepsSkeletonSC.Desktop>
	</>
);

export default StepsSkeleton;
