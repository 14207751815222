import React from 'react';
import Image from 'next/legacy/image';
import {
	FooterSC,
	FooterDisclaimerSC,
	SSLIconsLayout,
	SSLLayout,
	IconListLayout,
	IconLayout,
	LinksListLayout,
	LinkLayout,
	FooterLinks,
	LinksTitle,
	LinksList,
	LinksLayout,
} from './style';
import { Body } from 'ui-library/components/atoms/FontScale/Body';
import { FooterProps } from './types';
import { Link as CustomLink } from 'ui-library/components/atoms/Link';
import Link from 'next/link';
import { ThemeProvider } from 'styled-components';
import { Skeleton } from 'ui-library/components/layout/Skeleton';
import FooterSkeleton from './skeleton';

/**
 * Footer section component.
 * Includes cards list, links and the management for Disclaimer if it's configured as 'bottom'.
 * @component
 * @example
 *   <Footer content={...}/>
 */
export const Footer = ({
	nav,
	payments,
	links,
	linksLogo,
	footerDisclaimer,
	footerSSLSecure,
	variant = 'DEFAULT',
	isLoading = false,
}: FooterProps) => {
	if (isLoading)
		return (
			<Skeleton
				renderSkeleton={() => (
					<FooterSkeleton
						showDisclaimer={footerDisclaimer.showDisclaimer}
					/>
				)}
			/>
		);

	return (
		<ThemeProvider theme={{ variant }}>
			<FooterSC className="FooterSC">
				{variant === 'DEFAULT' && (
					<SSLIconsLayout>
						<SSLLayout>
							<Image
								unoptimized
								alt={
									footerSSLSecure?.image.alt ??
									'SSL Secure'
								}
								src={
									footerSSLSecure?.image.src ??
									'/common/ssl-secure-website_light-theme.webp'
								}
								width={48}
								height={48}
								quality={100}
							/>
							<Body size="S" variant="light">
								{footerSSLSecure?.body.text}
							</Body>
						</SSLLayout>
						<IconListLayout>
							{payments?.map(
								({
									image: {
										alt,
										src,
										width,
										height,
										title,
									},
								}) => (
									<IconLayout
										key={alt?.split(' ').join('')}>
										<Image
											unoptimized
											alt={alt}
											src={src}
											width={width ?? 40}
											height={height ?? 40}
											title={title}
											quality={100}
										/>
									</IconLayout>
								),
							)}
						</IconListLayout>
					</SSLIconsLayout>
				)}
				<LinksListLayout>
					{nav?.map(({ href, body, target }) => (
						<LinkLayout key={body.text.split(' ').join('')}>
							<CustomLink
								href={href}
								target={target ? '_blank' : '_self'}
								rel="noreferrer">
								{body.text}
							</CustomLink>
						</LinkLayout>
					))}
				</LinksListLayout>
				{/* Helpful links */}
				{variant === 'DEFAULT' && links && (
					<FooterLinks>
						<LinksTitle>
							{linksLogo && (
								<Image
									unoptimized
									src={linksLogo.src}
									alt={linksLogo.alt}
									title={linksLogo.title}
									width={linksLogo.width ?? 48}
									height={linksLogo.height ?? 48}
									quality={100}
								/>
							)}
							<Body size="S" variant="dark">
								Helpful Links
							</Body>
						</LinksTitle>
						<LinksList>
							{links?.map(({ href, body, target }) => (
								<LinksLayout
									key={body.text.split(' ').join('')}>
									<Link
										href={href}
										target={
											target ? '_blank' : '_self'
										}
										rel="noreferrer">
										{body.text}
									</Link>
								</LinksLayout>
							))}
						</LinksList>
					</FooterLinks>
				)}
				{variant === 'DEFAULT' &&
					footerDisclaimer.showDisclaimer && (
						<FooterDisclaimerSC>
							<Body size="S" variant="dark">
								{footerDisclaimer.body?.text}
							</Body>
						</FooterDisclaimerSC>
					)}
			</FooterSC>
		</ThemeProvider>
	);
};
