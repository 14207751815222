import styled, { css } from 'styled-components';
import { ButtonContent } from 'ui-library/components/atoms/Button/style';
import { media } from 'ui-library/lib/breakpoints';

const positionTop = css`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
`;

const positionBottom = css`
	position: relative;
`;

export const DisclaimerContainer = styled.div<{ $position: string }>`
	${({ $position }) =>
		$position === 'top' ? positionTop : positionBottom}

	display: flex;
	flex-direction: column;
	background-color: color-mix(
		in srgb,
		var(--color-basic-tooltip)
			var(--opacity-emphasis-surface-medium),
		transparent
	);
	padding: var(--spacing-_16);
	z-index: 1;
	${media.mdMin`
		padding: var(--spacing-_16) var(--spacing-_32);
	`}
`;

export const DisclaimerContent = styled.div<{ $isOpen: boolean }>`
	display: flex;
	text-align: left;
	margin-bottom: var(--spacing-_4);
	padding-right: var(--spacing-_16);
	p {
		margin-block-start: 0em;
		margin-block-end: 0em;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: ${({ $isOpen }) => ($isOpen ? 'auto' : 1)};
		overflow: hidden;
		text-overflow: ellipsis;
	}

	svg {
		cursor: pointer;
		flex: none;
	}
`;

export const ReadMore = styled(ButtonContent)`
	color: var(--color-text-onPrimary);
	background-color: transparent;
	font-weight: 700;
	&:hover:after {
		background-color: transparent;
	}
`;
