import getProductType from 'statics/services/getProductType';
import type { CrmData } from 'statics/services/AppContext';
import type { ProductType, Site } from 'statics/services/AppContext';
import { isServer } from '@tanstack/react-query';

let hostUrl: string = '';

if (isServer || window.location.hostname === 'localhost') {
	hostUrl = `https://${process.env!.NEXT_PUBLIC_HOST}`;
} else {
	hostUrl = window.location.origin;
}

if (!hostUrl) {
	throw new Error('Failed to determine host URL', {
		cause: 'NEXT_PUBLIC_HOST not set',
	});
}

export const CrmService = {
	hostUrl,
	async getCrmDataProductType(): Promise<ProductType> {
		const { product } = getProductType();
		try {
			const res = await fetch(
				`${this.hostUrl}/product/${product}.json`,
			);
			if (!res.ok) {
				throw new Error(
					`Failed fetching product crm data from ${hostUrl}/product/${product}.json`,
				);
			}
			return await res.json();
		} catch (err: unknown) {
			console.error('Error fetching product crm data', { err });
			throw new Error('Error fetching product crm data', {
				cause: err,
			});
		}
	},
	async getCrmDataSite(): Promise<Site> {
		try {
			const res = await fetch(`${this.hostUrl}/config.json`);
			if (!res.ok) {
				throw new Error(
					`Failed fetching site crm data from ${hostUrl}/config.json`,
				);
			}
			return await res.json();
		} catch (err: unknown) {
			console.error('Error fetching site config', { err });
			throw new Error('Error fetching site config', {
				cause: err,
			});
		}
	},
	async getCrmData(): Promise<CrmData> {
		const productType = await this.getCrmDataProductType();
		const site = await this.getCrmDataSite();
		return { productType, site };
	},
};

export default CrmService;
