import React from 'react';
import { ButtonSkeletonSC } from './style.skeleton';

export const ButtonSkeleton = (props: React.PropsWithChildren) => (
	<ButtonSkeletonSC
		speed={2}
		width={120}
		height={30}
		viewBox="0 0 120 30"
		backgroundColor="#f3f3f3"
		foregroundColor="#ece0e0"
		{...props}>
		<rect x="0" y="0" rx="3" ry="3" width="120" height="30" />
	</ButtonSkeletonSC>
);
