export interface ProductTypeProps {
	branch: string;
	product: string;
	theme: string;
}

/**
 * Get current Product and Theme from env variables.
 * @returns Object { branch, product, theme }
 */
export const getProductType = (): ProductTypeProps => {
	const productType = {
		branch: process.env!.GIT_BRANCH!,
		product: process.env!.NEXT_PUBLIC_PRODUCT || 'southkorea',
		theme: process.env!.NEXT_PUBLIC_THEME || 'southkorea1',
	};
	return productType;
};

export default getProductType;
